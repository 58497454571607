/**
 * Design System for Piaggio Group
*/
@import "abstracts";

// Brand specific variables
@import "tokens/tokens-piaggio";

// Vendor specific variables
@import "vendors/vendors";

@import "html"; //temporary style depending on html data

.theme-piaggio {
  @import "typography/var_icons-piaggio";
  @import "typography/santander_custom_piaggio";
  @import "main";
}