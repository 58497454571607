/**
 * Design System for Piaggio Group
*/

@font-face {
  font-family: "piaggio-icons";
  src: url("../fonts/piaggio/piaggio-icons.eot?3f442438c6f953893164c7a5bbabde79?#iefix")
      format("embedded-opentype"),
    url("../fonts/piaggio/piaggio-icons.woff2?3f442438c6f953893164c7a5bbabde79")
      format("woff2"),
    url("../fonts/piaggio/piaggio-icons.woff?3f442438c6f953893164c7a5bbabde79")
      format("woff"),
    url("../fonts/piaggio/piaggio-icons.ttf?3f442438c6f953893164c7a5bbabde79")
      format("truetype"),
    url("../fonts/piaggio/piaggio-icons.svg?3f442438c6f953893164c7a5bbabde79#piaggio-icons")
      format("svg");
  font-display: swap;
}

@import "tokens-styleguide-piaggio";
@import "tokens-components-piaggio";
